body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Désactive la selection des données du site */
	-moz-user-select: none; /* Firefox */
	-webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
	-ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
	user-select: none; /* Propriété standard */

	overflow-x: hidden;
	background-color: '#FBFBFB';
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Button Ripple Effect */
.custom-ripple {
	color: #fef4f1;
}

/* Ajoutez ces styles pour rendre la barre de défilement fine */
::-webkit-scrollbar {
	width: 3px; /* Largeur de la barre de défilement */
	height: 4px; /* Largeur de la barre de défilement */
}

::-webkit-scrollbar-thumb {
	background-color: #888; /* Couleur de la poignée de la barre de défilement */
	border-radius: 5px; /* Bordure de la poignée */
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Couleur de fond de la barre de défilement */
}

.sticky {
	position: sticky;
	left: 0;
	background-color: #fff;
	box-shadow: 5px 2px 5px grey;
	z-index: 1;
}
